define([
    "core/storage"
], function (storage) {
    "use strict";

    var exports = {};

    function parseLanguage(language) {
        var lang = "en";
        if (language.length === 2) {
            lang = language;
        }
        if (language.length > 2) {
            language = language.replace("_", "-");
            var separatorIndex = language.indexOf("-");
            if (separatorIndex >= 0) {
                lang = language.substr(0, separatorIndex);
            }
        }
        return lang;
    }

    exports = {
        getVersion: function () {
            return "3.05.2810.03";
        },

        getVersionForDisplay: function() {
            var version = "3.05.2810.03";
            var parts = version.split(".");
            parts.splice(3, 1);
            if (parts.length >= 3) {
                parts[2] = "0" + parts[2].slice(0, 2) + "." + "0" + parts[2].slice(2, 4);
            }
            return parts.join(".");
        },

        getUser: function() {
            return storage.get("maestro_user_profile_USERNAME");
        },

        setUser: function(user) {
            storage.set("maestro_user_profile_USERNAME", user);
        },

        clearUser: function() {
            storage.remove("maestro_user_profile_USERNAME");
        },

        getRoles: function () {
            return storage.get("maestro_user_profile_ROLES");
        },

        setRoles: function (roles) {
            storage.set("maestro_user_profile_ROLES", roles);
        },

        clearRoles: function () {
            storage.remove("maestro_user_profile_ROLES");
        },

        getToken: function() {
            return storage.get("TOKEN");
        },

        setToken: function(token) {
            storage.set("TOKEN", token);
        },

        clearToken: function() {
            storage.remove("TOKEN");
        },

        getLanguage: function () {
            var lang = storage.get("maestro_user_profile_LANGUAGE");
            if (lang === "default") {
                lang = "en";
            }
            if (!lang) {
                lang = window.navigator.language || window.navigator.browserLanguage;
            }

            return lang;
        },

        setLanguage: function(language) {
            var lang = parseLanguage(language);
            storage.set("maestro_user_profile_LANGUAGE", lang);
        },

        clearLanguage: function() {
            storage.remove("maestro_user_profile_LANGUAGE");
        },

        getCompany: function() {
            return storage.get("maestro_user_profile_COMPANYCODE");
        },

        getCompanyName: function () {
            return storage.get("maestro_user_profile_COMPANYNAME");
        },

        setCompany: function(company) {
            storage.set("maestro_user_profile_COMPANYCODE", company);
        },

        setCompanyName: function (companyName) {
            storage.set("maestro_user_profile_COMPANYNAME", companyName);
        },

        getPrefix: function() {
            return storage.get("maestro_user_profile_PREFIX");
        },

        setPrefix: function(prefix) {
            storage.set("maestro_user_profile_PREFIX", prefix);
        },

        getiOSNagger_documentUpload: function(){
            return storage.get("maestro_has_been_notified_to_upgrade_document_upload");
        },

        setiOSNagger_documentUpload: function () {
            storage.set("maestro_has_been_notified_to_upgrade_document_upload", "true");
        },

        cleariOSNagger_documentUpload: function () {
            storage.remove("maestro_has_been_notified_to_upgrade_document_upload");
        },

        clearCompany: function() {
            storage.remove("maestro_user_profile_COMPANYCODE");
            storage.remove("maestro_user_profile_COMPANYNAME");
        },

        getDispatchTemplate: function() {
            return storage.get("maestro_user_profile_TEMPLATECODE");
        },

        setDispatchTemplate: function(template) {
            storage.set("maestro_user_profile_TEMPLATECODE", template);
            exports.clearCurrentTemplateConfigs();
        },

        clearDispatchTemplate: function() {
            storage.remove("maestro_user_profile_TEMPLATECODE");
            storage.remove("maestro_user_profile_TEMPLATENAME");
            exports.clearCurrentTemplateConfigs();
        },

        getUnitSystem: function() {
            return storage.get("maestro_user_profile_UNITSYSTEM");
        },

        setUnitSystem: function (unit) {
            storage.set("maestro_user_profile_UNITSYSTEM", unit);
        },

        clearUnitSystem: function() {
            storage.remove("maestro_user_profile_UNITSYSTEM");
        },

        getUserAccessType: function () {
            return storage.get("maestro_user_profile_ACCESSTYPE");
        },

        setUserAccessType: function (accessType) {
            storage.set("maestro_user_profile_ACCESSTYPE", accessType);
        },

        clearUserAccessType: function () {
            storage.remove("maestro_user_profile_ACCESSTYPE");
        },

        clearAll: function() {
            exports.clearUser();
            exports.clearRoles();
            exports.clearToken();
            exports.clearCompany();
            exports.clearDispatchTemplate();
            exports.clearHiddenNavItems();
            exports.clearUserAccessType();
        },

        setCurrentTemplateConfigs:function(configs) {
            storage.set("CurrentTemplateConfigs", configs);
        },

        getCurrentTemplateConfigs: function () {
            return storage.get("CurrentTemplateConfigs");
        },

        clearCurrentTemplateConfigs: function () {
            storage.remove("CurrentTemplateConfigs");
        },

        setCurrentProjectWorkSchedule: function (configs) {
            storage.set("CurrentProjectWorkSchedule", configs);
        },

        getCurrentProjectWorkSchedule: function () {
            return storage.get("CurrentProjectWorkSchedule");
        },

        clearCurrentProjectWorkSchedule: function () {
            storage.remove("CurrentProjectWorkSchedule");
        },

        getiOSNagger_homeScreen: function () {
            return storage.get("maestro_has_been_notified_to_upgrade_homeScreen_shortcut");
        },

        setiOSNagger_homeScreen: function () {
            storage.set("maestro_has_been_notified_to_upgrade_homeScreen_shortcut", "true");
        },

        cleariOSNagger_homeScreen: function () {
            storage.remove("maestro_has_been_notified_to_upgrade_homeScreen_shortcut");
        },

        setTimesheetDefaultValues : function(values) {
            storage.set('maestro_timesheet_default_values', values);
        },

        getTimesheetDefaultValues : function() {
            return storage.get('maestro_timesheet_default_values');
        },

        getHiddenNavItems: function () {
            return storage.get('maestro_user_profile_hidden_nav_item');
        },

        setHiddenNavItems: function (values) {
            storage.set('maestro_user_profile_hidden_nav_item', values)
        },

        clearHiddenNavItems: function () {
            storage.remove('maestro_user_profile_hidden_nav_item');
        },

        getViewMode: function () {
            return storage.get("ViewMode");
        },

        setViewMode: function (value) {
            storage.set("ViewMode", value);
        },
    };

    return exports;
});
