import urlHelper from "helpers/urlHelper";
import val from "core/val";
import { default as settingHelper } from "helpers/settingHelper";
import { PagingInfo } from "api/paging-info";
import { autoinject, bindable } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";
import { ValidationHelper } from "helpers/validation-helper";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { CompanyService } from "services/company-service";
import { InventoryProjectTransferService } from "services/inventory-project-transfer-service";

import { CompanyModel } from "api/models/common/company/company-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { InventoryProjectTransferDetailsModel } from "api/models/company/inventory-project-transfer-details-model";
import { InventoryProjectTransferWorkOrderLookupModel } from "api/models/company/workorder/inventory-project-transfer-work-order-lookup-model";

@autoinject()
export class InventoryProjectTransferAdd {
    public val: any = val;
    public cies: CompanyModel[] | null = [];
    public sourceCies: CompanyModel[] | null = [];

    @bindable public option: any = "";
    @bindable public transfer!: InventoryProjectTransferDetailsModel | null;

    @bindable public isMultiPrefix: boolean = false;
    @bindable public isProjectLockedByWorkOrder: boolean = false;
    @bindable public isCieLockedByProject: boolean = false;
    @bindable public isWorkOrderVisible: boolean = false;

    constructor(
        private routeRepository: RouteRepository,
        private readonly inventoryProjectTransferService: InventoryProjectTransferService,
        private readonly companyService: CompanyService,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController
    ) {}

    public async activate(): Promise<void> {
        this.transfer = await this.inventoryProjectTransferService.NewTransfer();
        this.isWorkOrderVisible = await this.inventoryProjectTransferService.IsWorkOrdersInstalled();
        this.isMultiPrefix = settingHelper.isMultiPrefix();
        this.cies = await this.inventoryProjectTransferService.GetCompanies(true, null);
        this.sourceCies = await this.inventoryProjectTransferService.GetCompanies(true, null);
        this.initValidation();
    }

    public async getSourceCompanies(): Promise<CompanyModel[] | null> {
        return await this.companyService.getAll();
    }

    public async getTargetProjects(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ProjectBaseModel[] | null> {
        return await this.inventoryProjectTransferService.GetProjects("", filter, pagingInfo, requestConfig);
    }

    public async getWorkOrders(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<InventoryProjectTransferWorkOrderLookupModel[] | null> {
        return await this.inventoryProjectTransferService.GetWorkOrders("", filter, pagingInfo, requestConfig);
    }

    public async onWorkOrderChanged(event: any): Promise<void> {
        this.cies = await this.inventoryProjectTransferService.GetCompanies(true, null);

        this.isProjectLockedByWorkOrder = false;
        this.isCieLockedByProject = false;
        this.transfer!.ProjectNo = "";
        this.transfer!.ProjectDescription = "";
        this.transfer!.TargetPrefix = "";
        this.transfer!.TargetCompanyName = "";

        if (!!event.detail) {
            this.transfer!.ProjectNo = event.detail.ProjectNo;
            this.transfer!.ProjectDescription = event.detail.ProjectDescription;
            this.isProjectLockedByWorkOrder = true;
            if (event.detail.Prefix !== "") {
                const cieTargetToSelect = this.cies!.find((cie: CompanyModel) => cie.Prefix === event.detail.Prefix);
                this.transfer!.TargetPrefix = cieTargetToSelect ? cieTargetToSelect.Prefix : "";
                this.transfer!.TargetCompanyName = cieTargetToSelect ? cieTargetToSelect.Name : "";
                this.isCieLockedByProject = cieTargetToSelect ? true : false;
            }
        }
    }

    public async onProjectChanged(event: any): Promise<void> {
        if (this.transfer!.WorkOrderId) {
            return;
        }

        this.cies = await this.inventoryProjectTransferService.GetCompanies(true, null);

        this.transfer!.TargetPrefix = "";
        this.transfer!.TargetCompanyName = "";
        this.isCieLockedByProject = false;

        if (!!event.detail) {
            if (event.detail.Prefix !== "" && !event.detail.Prefix.includes("X")) {
                const cieTargetToSelect = this.cies!.find((cie: CompanyModel) => cie.Prefix === event.detail.Prefix);
                this.transfer!.TargetPrefix = cieTargetToSelect ? cieTargetToSelect.Prefix : "";
                this.transfer!.TargetCompanyName = cieTargetToSelect ? cieTargetToSelect.Name : "";
                this.isCieLockedByProject = cieTargetToSelect ? true : false;
            } else if (event.detail.Prefix.includes("X")) {
                this.cies = await this.inventoryProjectTransferService.GetCompanies(true, event.detail.Prefix);
                if (this.transfer!.TargetPrefix !== "") {
                    const cieTargetToSelect = this.cies!.find((cie: CompanyModel) => cie.Prefix === this.transfer!.TargetPrefix);
                    this.transfer!.TargetPrefix = cieTargetToSelect ? cieTargetToSelect.Prefix : "";
                    this.transfer!.TargetCompanyName = cieTargetToSelect ? cieTargetToSelect.Name : "";
                    this.isCieLockedByProject = cieTargetToSelect ? true : false;
                }
            }
        }
    }

    public async save(): Promise<void> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        if (isValid !== true) {
            return;
        }

        await this.inventoryProjectTransferService.SaveTransfer(this.transfer!).then((result: number | null): any => {
            this.replaceBrowsingHistory();
            routerHelper.navigateToRoute(this.routeRepository.routes.InventoryProjectTransfer_Info.name,  { transferId: result });
        });
    }

    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;
        this.validateTransfer();
    }

    private validateTransfer(): void {
        ValidationRules
            .ensure((x: InventoryProjectTransferDetailsModel) => x.ProjectNo).required().withMessageKey("err_ProjectRequired")
            .on(this.transfer);
    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        const route =  this.routeRepository.routes.InventoryProjectTransfer.route.toString();
        window.history.replaceState("", "", baseUrl + route);
    }
}
