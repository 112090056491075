import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { ServiceCallEquipmentProxy } from "api/proxies/service-call-equipment-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ServiceCallContractEquipmentStatusModel } from "api/models/company/service/service-call-contract-equipment-status-model";
import { ServiceCallEquipmentDetailModel } from "api/models/company/service/service-call-equipment-detail-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallContractEquipmentReplaceModel } from "api/models/company/service/service-call-contract-equipment-replace-model";
import { moment } from "fullcalendar";
import { ServiceCallEquipmentBrandModel } from "api/models/company/service/service-call-equipment-brand-model";
import { ServiceCallEquipmentCategoryModel } from "api/models/company/service/service-call-equipment-category-model";

@autoinject()
export class ServiceCallContractEquipmentService {
    private equipmentProxy: ServiceCallEquipmentProxy;

    constructor(serviceCallContractEquipmentProxy: ServiceCallEquipmentProxy) {
        this.equipmentProxy = serviceCallContractEquipmentProxy;
    }

    public async getContractEquipments(contractId: string, jobDate: Date | null, filter?: string): Promise<ServiceCallContractEquipmentModel[] | null> {

        let currentJobDate = jobDate;
        if (jobDate) {
            currentJobDate = moment(jobDate).format("YYYY-MM-DD");
        }

        return await this.equipmentProxy.GetContractEquipments(contractId, currentJobDate, filter);
    }

    public async get(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentStatusModel[] | null> {
       return this.equipmentProxy.Get(dispatchId, filter, pagingInfo, requestConfig);
    }

    public async getNewServiceCallContractEquipments(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentModel[] | null> {
       return this.equipmentProxy.GetNewServiceCallContractEquipments(filter, pagingInfo, requestConfig);
    }

    public async getForEquipment(dispatchId: number, equipmentId: number, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallContractEquipmentModel | null> {
        return this.equipmentProxy.GetForEquipment(dispatchId, equipmentId, requestConfig);
    }

    public async getForCallAndEquipment(callNumber: number, equipmentCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentDetailModel | null> {
        return this.equipmentProxy.GetForCallAndEquipment(callNumber, equipmentCode, requestConfig);
    }

    public async post(dispatchId: number, model: ServiceCallContractEquipmentStatusModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.equipmentProxy.Post(dispatchId, model, requestConfig);
    }

    public async updateContractEquipment(dispatchId: number, equipment: ServiceCallContractEquipmentModel, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.equipmentProxy.UpdateContractEquipment(dispatchId, equipment, requestConfig);
    }

    public async replaceServiceCallContractEquipment(dispatchId: number, replaceModel: ServiceCallContractEquipmentReplaceModel, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
      return this.equipmentProxy.ReplaceServiceCallContractEquipment(dispatchId, replaceModel, requestConfig);
    }

    public async allowedServiceCallContractEquipmentManagement(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.equipmentProxy.AllowedServiceCallContractEquipmentManagement(requestConfig);
    }

    public async getServiceCallContractEquipmentBrands(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentBrandModel[] | null> {
        return this.equipmentProxy.GetServiceCallContractEquipmentBrands(filter, pagingInfo, requestConfig);
    }

    public async getServiceCallContractEquipmentCategories(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ServiceCallEquipmentCategoryModel[] | null> {
        return this.equipmentProxy.GetServiceCallContractEquipmentCategories(filter, pagingInfo, requestConfig);
    }
}
