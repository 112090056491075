import { autoinject, bindable, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import val from "core/val";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { ServiceCallContractEquipmentModel } from "api/models/company/service/service-call-contract-equipment-model";
import { EquipmentEditParameters } from "components/pages/services/equipment-details/equipment-details-informations";
import Parse from "helpers/parse";
import { ServiceCallEquipmentBrandModel } from "api/models/company/service/service-call-equipment-brand-model";
import { PagingInfo } from "api/paging-info";
import { IRequestConfig } from "models/request-config";
import { ServiceCallEquipmentCategoryModel } from "api/models/company/service/service-call-equipment-category-model";
import { CatalogService } from "services/catalog-service";
import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";

@autoinject
export class EquipmentEdit {
    public isNewEquipment: boolean = false;
    public isReplacement: boolean = false;
    public equipmentId: number | null = 0;

    public dispatchId: any;
    public isEquipmentSelected: boolean = false;
    public val: any = val;
    public readonly: boolean = true;
    public coolantIdMaxLength: number = val.get("catalog.code", "maxLength");

    @bindable
    public equipment: ServiceCallContractEquipmentModel | null = null;

    public replacedEquipment: ServiceCallContractEquipmentModel | null = null;

    @bindable
    public selectedEquipment: any | null = null;

    @observable public selectedCoolantId: any = null;
    public selectedCoolantDescription: any = null;
    public selectedCoolantDescription2: any = null;
    public selectedCoolantDescription3: any = null;

    @observable public notInCatalogCoolantId: any = null;

    constructor(private readonly i18n: I18N, private readonly equipmentService: ServiceCallContractEquipmentService, private readonly catalogService: CatalogService) {
        this.i18n = i18n;
        this.equipmentService = equipmentService;
        this.catalogService = catalogService;
    }

    public async activate(params: EquipmentEditParameters): Promise<void> {
        this.dispatchId = params.dispatchId;
        this.isNewEquipment =  Parse.Boolean(params.isNewEquipment);
        this.isReplacement =  Parse.Boolean(params.isReplacement);
        this.equipmentId = params.equipmentId;
        this.readonly = !Parse.Boolean(params.allowedServiceCallContractEquipmentManagement) || Parse.Boolean(params.readonly);

        this.selectedCoolantId = null;
        this.selectedCoolantDescription = null;
        this.selectedCoolantDescription2 = null;
        this.selectedCoolantDescription3 = null;
        this.notInCatalogCoolantId = null;

        if (!this.isNewEquipment && !this.isReplacement) {
            this.equipmentId = params.equipmentId;
            this.equipment = await this.equipmentService.getForEquipment(this.dispatchId, this.equipmentId!);
                
            if (this.equipment!.NotInCatalog) {
                this.notInCatalogCoolantId = this.equipment!.CoolantId;
            } else if (this.equipment!.CoolantId)  {
                this.selectedCoolantId = this.equipment!.CoolantId;
                this.selectedCoolantDescription = this.equipment!.CoolantDescription;
                this.selectedCoolantDescription2 = this.equipment!.CoolantDescription2;
                this.selectedCoolantDescription3 = this.equipment!.CoolantDescription3;
            }

            this.initialiseExistingEquipment(this.equipment!);
        }
        if (this.isReplacement) {
            this.replacedEquipment = await this.equipmentService.getForEquipment(this.dispatchId, this.equipmentId!);
        }
    }

    public selectedCoolantIdChanged(newValue: any, oldValue: any): void {
        if (newValue) {
            this.notInCatalogCoolantId = null;
        }
    }

    public notInCatalogCoolantIdChanged(newValue: any, oldValue: any): void {
        if (newValue) {
            this.selectedCoolantId = null;
            this.selectedCoolantDescription = null;
            this.selectedCoolantDescription2 = null;
            this.selectedCoolantDescription3 = null;
        }
    }

    public getSelectedItemDisplay(equipment: ServiceCallContractEquipmentModel): string {
        return `${equipment!.Code} - ${equipment.Description}`;
    }

    public initialiseExistingEquipment(equipment: ServiceCallContractEquipmentModel): void {
        this.selectedEquipment = { id: equipment.Code, text: this.getSelectedItemDisplay(equipment), data: equipment };
        this.isEquipmentSelected = true;
    }

    public get GetEquipments(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.equipmentService.getNewServiceCallContractEquipments(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ServiceCallContractEquipmentModel): any => {
                return { id: item.Code, text: this.getSelectedItemDisplay(item), data: item };
            },
        };
    }

    public async populateBrands(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallEquipmentBrandModel[] | null> {
        return await this.equipmentService.getServiceCallContractEquipmentBrands(
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public async populateCategories(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<ServiceCallEquipmentCategoryModel[] | null> {
        return await this.equipmentService.getServiceCallContractEquipmentCategories(
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public async populateMaterials(filter: string, pagingInfo: PagingInfo, requestConfig: IRequestConfig): Promise<CatalogItemModel[] | null> {
        return await this.catalogService.GetCatalogItemsForInventoryTransfer(
            filter,
            pagingInfo,
            requestConfig
        );
    }

    public onEquipmentSelected(item: ServiceCallContractEquipmentModel): void {
        const dialogMessage = this.i18n.tr("ms_ChangeEquipmentConfirmation");

        if (this.isEquipmentSelected) {
            notificationHelper.showDialogYesNo(dialogMessage).then(async (success: boolean): Promise<void> => {
                if (success) {
                    this.equipment = item;
                }
            });
        } else {
            this.equipment = item;
            this.isEquipmentSelected = true;
        }
    }

    public async selectedBrandChanged(event: CustomEvent<ServiceCallEquipmentBrandModel>): Promise<void> {
        const brandModel = event ? event.detail : null;

        this.equipment!.Brand = brandModel ? brandModel.Code : null;
        this.equipment!.BrandDescription = brandModel ? brandModel.Description : null;
    }

    public async selectedCategoryChanged(event: CustomEvent<ServiceCallEquipmentCategoryModel>): Promise<void> {
        const categoryModel = event ? event.detail : null;

        this.equipment!.Category = categoryModel ? categoryModel.Code : null;
        this.equipment!.CategoryDescription = categoryModel ? categoryModel.Description : null;
    }

    public async selectedCoolantChanged(event: CustomEvent<CatalogItemModel>): Promise<void> {
        const catalogItemModel = event ? event.detail : null;

        this.equipment!.CoolantId = catalogItemModel ? catalogItemModel.Code : null;
        this.equipment!.CoolantDescription = catalogItemModel ? catalogItemModel.Description1 : null;
        this.selectedCoolantDescription2 = catalogItemModel ? catalogItemModel.Description2 : null;
        this.selectedCoolantDescription3 = catalogItemModel ? catalogItemModel.Description3 : null;
    }

    public async save(): Promise<void> {
        const isInvalid = !this.isEquipmentSelected && this.equipment!.DateEffective === null;

        if (isInvalid) {
            return;
        }

        this.equipment!.CoolantId = this.notInCatalogCoolantId ? this.notInCatalogCoolantId : this.selectedCoolantId;

        if (this.isReplacement) {
            await this.equipmentService.replaceServiceCallContractEquipment(this.dispatchId, { NewActiveEquipment: this.equipment,
                InactiveEquipment: this.replacedEquipment });
        } else {
            await this.equipmentService.updateContractEquipment(this.dispatchId, this.equipment!);
        }

        routerHelper.navigateBack();
    }
}
